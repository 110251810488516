@import "../../../../../../configuration/sass/variables";

.briefAction {
  display: flex;
  border-bottom: 1px solid transparentize($ui-Grey2, 0.5);
  margin-bottom: 1em;

  .icon {
    flex: 0 0 10%;

    img {
      max-width: 50px;
    }
  }
  .mainAction {
    flex: 1;
    .title {
      color: $ui-Black;
      font-size: 1.1em;
      font-weight: 600;
      margin-top: 15px;

      .subtitle {
        color: $ui-Grey2;
        font-weight: 600;
        i {
          padding: 0 1.2em; 
        }
      }
    }
    .content {
      display: flex;
      .mainInfo {
        flex: 1;
        display: flex;
        flex-wrap: wrap;

        dl {
          flex: 1;
          margin-right: 1em;

          dt {
            color: $ui-Black2;
            font-size: 0.95em;
            text-transform: uppercase;
          }

          dd {
            color: $ui-Black2;
          }
        }
      }
      .actionButtons {
        flex: 0 0 10%;

        i {
          font-size: 24px;
          opacity: 0.6;
          color: $ui-Grey1;
        }
      }
    }
  }
}
