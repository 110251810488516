@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
$grid-breakpoints: (
  xxxs: 0,
  xxs: 320px,
  xs: 568px,
  sm: 667px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 2048px,
  4k: 3000px,
);
$container-max-widths: (
  xxxs: 0,
  xxs: 320px,
  xs: 568px,
  sm: 667px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 2048px,
  4k: 3000px,
);
@import "~bootstrap/scss/mixins";
@import "_colors.scss";
@import "_fonts.scss";
@import "./fonts/fonts.scss";
@import "_mixins.scss";

$navBarHeightForDesktop: 72px;
$navBarHeightForMobile: 48px;
$navBarHeightForMobileLogo: 52px;
$navBarHeightForLargerScreens: $navBarHeightForDesktop * 1.6;

$navBarHeight: $navBarHeightForDesktop;

@media (min-width: 1800px) {
  .container {
    max-width: 1760px;
  }
}

@media (min-width: 3000px) {
  .container {
    max-width: 2960px;
  }
}
