@import "../../../configuration/sass/variables";

.continueText {
  color: $ui-Black2;
  text-align: center;

  button {
    margin: 0 0.5em;
  }
}
.mapButton{
  color: $ui-Grey1;
  &.editable {
    border-color: $ui-tertiary!important;
    color: $ui-tertiary!important;
    svg {
      g g g g {
        fill: $ui-tertiary;
      }
    }
  }
}

.alertWrapper {
  flex: 1 1;
  display: flex;
  align-items: center;
  &>div {
    width: 50%;
    margin-right: auto;
    padding: 10px;
    margin-bottom: 0px;
    font-size: 0.933em;
  }
}

.alertWrapperInTab {
  flex: 1 1;
  display: flex;
  align-items: center;
  &>div {
    margin-top: 20px;
    margin-right: auto;
    padding: 10px;
    margin-bottom: 0px;
    font-size: 0.933em;
  }
}
