@import "../../../../../../configuration/sass/variables";

.condition {
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;

  .conditionalButton,
  .actionsButtons {
    flex: 0 0 20%;
    display: flex;
    align-items: center;
  }

  .conditionalButton {
    &:after {
      content: "";
      width: 100%;
      border-bottom: 1px solid $ui-primary;
    }
  }

  .actionsButtons {
    flex: 0 0 20%;
  }

  .commonFields {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-left: 0;
    padding-right: 0;

    & > * {
      margin-bottom: -0.2rem !important;
      display: flex;
      align-items: center;
      flex-direction: row;
      min-width: 0;
      flex: 0 0 25%;

      button {
        min-width: auto!important;
      }
      & > :first-child {
        flex: 1;
      }
      &:not(:last-child):after {
        content: "";
        flex: 0 0 10%;
        border-bottom: 1px solid $ui-primary;
      }
    }
  }

  &.nested {
    .conditionalButton {
      padding-left: 10%;
    }
    &.noSelector {
      content: none;
      .conditionalButton {
        &:after {
          content: none;
        }
      }
    }
  }
}
