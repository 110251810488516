.default-var,
.operation {
  color: #2b334a;
  padding: 7px;
  cursor: pointer;
  margin: 2px;
  display: inline-block;
}
.default-var,
.default-var-kpi {
  background: #e9edfc;
}
.operation {
  background: rgba(172, 211, 86, 0.25);
}
