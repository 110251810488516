@import "../../../configuration/sass/variables.scss";

.complexTextarea {
  min-height: 200px;
  overflow-wrap: break-word;

  & > tags {
    --tags-border-color: transparent;
    --tag-bg: #{transparentize($ui-primary, 0.9)};
    --tag-hover: #{transparentize($ui-primary, 0.8)};
    --tag-text-color: #{$ui-primary};
    --tag-text-color--edit: #{$ui-primary};
    --tag-pad: 0.3em 0.5em;
    --tag-inset-shadow-size: 1.1em;
    --tag-invalid-color: #{$alert-error};
    --tag-invalid-bg: #{transparentize($alert-error, 0.5)};
    --tag-remove-bg: rgba(211, 148, 148, 0.3);
    --tag-remove-btn-bg: none;
    --tag-remove-btn-bg--hover: #c77777;
    --tag--min-width: 1ch;
    --tag--max-width: auto;
    --tag-hide-transition: 0.3s;
    --placeholder-color: #{$ui-Grey1};
    --loader-size: 0.8em;
    border: 0;
  }
}
