@import "../../configuration/sass/variables";

.switchElement {
  height: 100%;
  border-radius: 0.75rem;
  padding: 3px;
  overflow: hidden;
  -webkit-transition: 0.25s ease all;
  transition: 0.25s ease all;
  background: $ui-primary;
  /* border: 1px solid black; */
  /* position: absolute; */
  width: 36px;
  /* left: 6rem; */
  display: inline-block;

  .point {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: white;
    -webkit-transition: 0.25s ease all;
    transition: 0.25s ease all;
  }
}

.switchNameAttribute {
  margin-bottom: 5.5px;
}

.disabledSwitch > *:first-child {
  background-color: #e5e8ea !important;
}
