.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: none !important;
  width: 100%;
  flex-direction: row !important;

  div:last-child {
    position: absolute;
    right: 0px;
  }
}
