@import '../../../../configuration/sass/variables.scss';
@import '../../../../configuration/sass/colors';
@import '../../../../configuration/sass/fonts';

.item-widget {
  &.selected {
    g {
      fill: $ui-main-color !important;
    }
   }
}
.item-widget-slider {
  &.selected {
    g{
      fill: $ui-main-color !important;
      path {
        fill: $ui-main-color !important;
      }
    }
  }
}

.preview-linked{
  height: 500px;
}

  .commandAttributes {
    .row {
      align-items: center;
    }
  }

.commandGroupContainer {
  .commandGroupHeader {
    display: flex;
    .commandGroupTittle {
      .commandGroupToggler {
        color: $widget-quinary-1;
        font-size: 1.6em;
        padding-right: 10px;
      }
      .commandName {
        color: $ui-Black;
        font-family: $typography;
        font-size: 1em;
        font-weight: bold;
        letter-spacing: 0;
      }
    }
    .commandGroupOptions {
      color: $widget-quinary-1;
      margin-left: 141px;
    }

    .commandGroupNumberOfCommands {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $ui-Grey3;
      border-radius: 50%;
      height: 20px;
      color: $widget-quinary-1;
      font-weight: bold;
      font-family: Rubik;
      font-size: 0.9em;
      letter-spacing: 0;
      line-height: 14px;
    }
  }
  .commandGroupBody {
    display: flex;
    .commandGroupFields {
      width: 50%;
    }
  }
}
.field{
  font-weight: bold;
}