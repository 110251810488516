@import "./configuration/sass/variables";
@import "~bootstrap/scss/bootstrap";

html,
body,
button,
input,
optgroup,
select,
textarea {
  background-color: #f9fafc;
  font-size: $baseFontSize;
  @include media-breakpoint-up(4k) {
    font-size: $baseFontSize * 1.6;
  }
  font-weight: 400;
  text-rendering: optimizeLegibility;
}

button,
input,
optgroup,
select,
textarea {
  @include media-breakpoint-up(4k) {
    line-height: 2em;
  }
}

.modal-res {
  width: 100%;
  @include media-breakpoint-up(md) {
    width: 80%;
  }
}

* {
  font-family: $typography;
  box-sizing: border-box;
}

a {
  color: $ui-primary;
  &:hover {
    color: $ui-primary-focus;
  }
}

h1 {
  color: $ui-Black;
  font-family: $typography;
  font-size: 1.6em;
  font-weight: 600;
  line-height: 1.2em;
}

h2 {
  color: $ui-Black;
  font-family: $typography;
  font-size: 0.933em;
  font-weight: 600;
  line-height: 1.2em;
}

h3 {
  color: $ui-Grey1;
  font-family: $typography;
  font-size: 0.933em;
  line-height: 1.2em;
}

::placeholder {
  color: $ui-Grey1;
  font-size: 0.933em;
  font-style: normal;
  line-height: 1.2em;
}

i {
  cursor: pointer;
}

.card-item {
  color: $ui-Black;
  font-family: $typography;
  font-size: 1.2em;
  line-height: 1.2em;
}

.button {
  color: $ui-primary;
  font-family: $typography;
  font-size: 1.07em;
  letter-spacing: 0.32px;
  line-height: 1.2em;
}

.text-gray {
  color: $ui-Black2;
  font-family: $typography;
  font-size: 0.933em;
  line-height: 1.2em;
}

.text-subtle {
  color: $ui-Grey1;
  font-family: $typography;
  font-size: 0.933em;
  line-height: 1.2em;
}

.caption {
  color: $ui-Black2;
  font-family: $typography;
  font-size: 0.8em;
  line-height: 1.2em;
}

a:hover {
  cursor: pointer;
}

.bottom-right-0 {
  bottom: 0px;
  right: 0px;
}

.image-vw-vh-100 {
  max-width: 100vw;
  max-height: 100vh;
}

input.error-input {
  border: red 1px solid;
}

input {
  -webkit-appearance: unset;
  outline-offset: unset;
  outline: unset;
  border: unset;
  margin-bottom: 15px;
}

.no-button {
  border: none;
  outline: none;
  box-shadow: none;
  &:focus {
    outline: none;
    border: none;
  }
}

.verticalDivisor {
  box-sizing: border-box;
  height: 107px;
  width: 2px;
  border: 1px solid #e0e7ff;
}

.BreadCrumbs {
  width: 100%;
  @include media-breakpoint-down(md) {
    margin-bottom: 24px;
  }
}

.btn {
  &.btn-default {
    border: 1px solid $ui-primary;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.03);
    min-width: 0;
    padding: 2px;
    border-radius: 20px;
  }

  &.btn-secondary{
    box-shadow: none;
  }

  &.condition-if {
    border-radius: 3px;
    background-color: $ui-primary;
    box-shadow: 0 3px 4px 0 rgba(62, 90, 102, 0.16);
    color: white;
    font-size: 1em;
    font-weight: bold;
    line-height: 1.2em;
  }
  &.condition-and,
  &.condition-or {
    border: 1px solid $ui-primary;
    border-radius: 3px;
    box-shadow: 0 3px 4px 0 rgba(62, 90, 102, 0.16);
    background-color: white;
    position: relative;
    z-index: 1;
  }
}

.optionsForTop {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
  p.total {
    color: $ui-Grey2;
    font-family: $typography;
    font-size: 0.8rem;
    font-weight: bold;
    letter-spacing: 1.13px;
    line-height: 1.2em;
    text-transform: uppercase;
    margin-bottom: 0;

    span {
      color: $ui-primary;
      font-family: $typography;
      font-size: 0.8rem;
      font-weight: 600;
      line-height: 1.2em;
      text-align: center;
      background-color: transparentize($ui-primary, 0.8);
      padding: 1px 5px;
      border-radius: 10px;
      margin-left: 5px;
    }
  }
}

.passwordPanel {
  height: 130px;
  border: 1px solid rgba(87, 126, 232, 0.6);
  opacity: 0.56;
  border-radius: 12px;
  background-color: #f9fafc;
  display: flex;

  justify-content: space-around;
  align-items: center;

  & > div:not(.divisor) {
    flex: 1;
  }

  dt {
    color: #b0bac9;
    font-size: 0.8em;
    font-weight: 400;
    letter-spacing: 1.13px;
    line-height: 1.2em;
    display: block;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 18px;
  }

  dd {
    color: $ui-Black2;
    font-size: 1em;
    line-height: 1.2em;
    text-align: center;
  }

  .divisor {
    box-sizing: border-box;
    height: 107px;
    width: 2px;
    border: 1px solid #e0e7ff;
  }
}

.devicesStarter,
.servicesStarter,
.kpisStarter {
  padding: 54px 0px 54px;
  h1 {
    color: $ui-Black;
    font-size: 1.6em;
    line-height: 1.2em;
    letter-spacing: 0px;
  }
  .lead {
    color: $ui-Black2;
    font-size: 1.34em;
    line-height: 1.2em;
    text-align: center;
    margin-bottom: 54px;
  }
  img {
    height: 8rem;
  }
  .mainContent {
    justify-content: space-around;
    width: 80%;

    @include media-breakpoint-up(4k) {
      width: 50%;
    }
    margin: 0px auto;
    .cardButton {
      background-color: white;
      padding: 33px 26px;
      cursor: hand;
      border-radius: 6px;
      border: 1px solid #fff;

      &:hover {
        border: 1px solid $ui-primary;
        box-shadow: 0 3px 24px 0 rgba(62, 90, 102, 0.16);
      }
    }

    h3 {
      color: $ui-Black;
      font-size: 1em;
      font-weight: 600;
      line-height: 1.2em;
      margin-bottom: 8px;
    }

    p {
      width: 256px;
      color: $ui-Black;
      font-size: 1.07em;
      line-height: 1.2em;
      text-align: center;
    }
  }
}

#chart {
  height: 100%;
  width: 100%;

  div:first-child {
    height: 100%;
  }
}

.tagify__dropdown {
  z-index: 999999999999;
}
