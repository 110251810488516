@font-face {
    font-family: 'PF Bean Sans Pro';
    src: url('pfbeausanspro-regular-5932e0de4131e-webfont.eot');
    src: url('pfbeausanspro-regular-5932e0de4131e-webfont.eot?#iefix') format('embedded-opentype'),
         url('pfbeausanspro-regular-5932e0de4131e-webfont.woff2') format('woff2'),
         url('pfbeausanspro-regular-5932e0de4131e-webfont.woff') format('woff'),
         url('pfbeausanspro-regular-5932e0de4131e.ttf') format('truetype'),
         url('pfbeausanspro-regular-5932e0de4131e-webfont.svg') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'PF Bean Sans Pro';
    src: url('pfbeausanspro-xthin-5932e2baf358f-webfont.eot');
    src: url('pfbeausanspro-xthin-5932e2baf358f-webfont.eot?#iefix') format('embedded-opentype'),
         url('pfbeausanspro-xthin-5932e2baf358f-webfont.woff2') format('woff2'),
         url('pfbeausanspro-xthin-5932e2baf358f-webfont.woff') format('woff'),
         url('pfbeausanspro-xthin-5932e2baf358f.ttf') format('truetype'),
         url('pfbeausanspro-xthin-5932e2baf358f-webfont.svg') format('svg');
    font-weight: 300;
    font-style: normal;

}

@font-face {
    font-family: 'PF Bean Sans Pro';
    src: url('pfbeausanspro-bold_35345-webfont.eot');
    src: url('pfbeausanspro-bold_35345-webfont.eot?#iefix') format('embedded-opentype'),
         url('pfbeausanspro-bold_35345-webfont.woff2') format('woff2'),
         url('pfbeausanspro-bold_35345-webfont.woff') format('woff'),
         url('pfbeausanspro-bold_35345.ttf') format('truetype'),
         url('pfbeausanspro-bold_35345-webfont.svg') format('svg');
    font-weight: bold;
    font-style: normal;

}
$typography: 'PF Bean Sans Pro', sans-serif;
$secondaryTypography: 'unicons', sans-serif;

$baseFontSize: 15px;

@media (min-width: 1850px){
    $baseFontSize: $baseFontSize * 2;
}
