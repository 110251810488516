// Main Color
$ui-main-color: #fb2245;

// System
$ui-primary: #fb2245;
$ui-primary-focus: darken($ui-primary, 10% );
$ui-secondary: #920034;
$ui-tertiary: #888B8D;

// Text & Background colors
$ui-Black: #2b334a;
$ui-Black2: #6a7886;
$ui-Grey1: #9da7b0;
$ui-Grey2: #ced3d7;
$ui-Grey3: #E0E7FF;
$ui-LightBG: #efefef;
$ui-White: #ffffff;
$ui-Modal: rgba(87,105,153,0.645);
$ui-Cancel-Buttom: #5A6268;

// Alert colors
$alert-ok: #05c985;
$alert-danger: #f1af00;
$alert-error: #fc381d;

// Severity colors
$severity-informational: #0f51a5;
$severity-low: #00d3a4;
$severity-medium: #f7e13c;
$severity-high: #f1af00;
$severity-critical: #fc381d;

//Widget colors
$widget-primary-1: #e12c50;
$widget-primary-2: #f64770;
$widget-primary-3: #f97893;
$widget-primary-4: #faa4b7;
$widget-primary-5: #facfd9;

$widget-secondary-1: #b9bd00;
$widget-secondary-2: #c4ca00;
$widget-secondary-3: #d1d64d;
$widget-secondary-4: #dee38d;
$widget-secondary-5: #ebeec5;

$widget-tertiary-1: #0f51a5;
$widget-tertiary-2: #2974bb;
$widget-tertiary-3: #6196cc;
$widget-tertiary-4: #95b7dc;
$widget-tertiary-5: #c7d8ec;

$widget-quaternary-1: #fcac00;
$widget-quaternary-2: #fbbd00;
$widget-quaternary-3: #fccc4d;
$widget-quaternary-4: #fadb8d;
$widget-quaternary-5: #f9ebc5;

$widget-quinary-1: #577ee8;
$widget-quinary-2: #7097f3;
$widget-quinary-3: #92b0f4;
$widget-quinary-4: #b5c8f8;
$widget-quinary-5: #d6e1f9;

$widget-info: #fff9e2;
$wizard-grey: #f9fafc;