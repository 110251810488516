@import "../../../../../../configuration/sass/variables";

.timeRule {

    display: flex;
    align-items: baseline;
    flex-direction: row;
    justify-content: center;
    gap: 0.1rem;
    margin-bottom: 1rem;

    .conditionContainer{
        display: flex;
        align-items: baseline;
    }

    .occurCont{
        width: 9rem;
        display: flex;
        align-items: center;

        .occurBox {
        border-radius: 0.2rem;
        background-color: $ui-primary;
        color: $ui-White;
        width: 8rem;
        text-align: center;
        padding: 0.3rem 1rem;
        font-weight: bold;
        &:before{
            content: '';
            width: 1rem;
            border-left: 1px solid transparentize($ui-primary, 0.5);
            height: 24px;
            position: relative;
            top: -25px;
            left: 18px;
            z-index: 0;
        }
        }
    }

    .occurCont, .lineCont{
        &:after{
            content: "";
            width: 100%;
            border-bottom: 1px solid $ui-primary;
        }
    }
    
    .lineCont{
        display: flex;
        align-items: center;
    }

    .box{
        width: 5rem;
    }

    .big{
        width: 8rem;
    }

    .timesBox{
        border-radius: 0.2rem;
        border: 1px solid $ui-primary;
        background-color: $ui-White;
        color: $ui-primary;
        width: 7rem;
        text-align: center;
        padding: 0.3rem 0.6rem;
        font-weight: bold;

        &:after{
            content: "";
            width: 100%;
            border-bottom: 1px solid $ui-primary;
        }
    }

    .field{
        margin: 0;
        .innerField{
            padding: 0.667rem 1.07rem;
        }

        .number {
            .innerField{
                display: flex;
                align-items: center;    
            }
        }
        span {
            text-align: center;
            font-size: 1em;
        }
    }

    .numberTimes{
        width: 6rem;
    }

    .valueField{
        width: 25rem;
    }

    .timeUnit{
        span{
            color: $ui-Grey1;
            font-size: 0.933em;
            font-style: normal;
            line-height: 1.2em;
            font-weight: initial;
        }
    }
}
